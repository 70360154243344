import React, { useEffect, useRef, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { navigate } from "gatsby";
import moment from "moment";
import Swal from "sweetalert2";

import MassiveUserData from "~/components/Appointments/MassiveUserDataLoader";
import AppointmentData from "~/components/Appointments/Offline/AppointmentData";
import DiscountCodeApplier from "~/components/Appointments/Offline/DiscountCodeApplier";
import NurseAndLabServices from "~/components/Appointments/Offline/NurseAndLabServices";
import SalesSourceSelector from "~/components/Appointments/Offline/SalesSourceSelector";
import UserData from "~/components/Appointments/UserData";
import PrivateRoute from "~/components/Authentication/PrivateRoute";
import LoadingError from "~/components/Loaders/LoadingError";
import { useScrollTop } from "~/hooks";
import useAddessValue from "~/hooks/useAddressValue";
import appointmentService from "~/utils/api/v1/appointmentService";
import { CartItemObjectType } from "~/utils/interfaces/cartItem";
import { SheetError } from "~/utils/interfaces/Flatfile";
import { IDoctorData, IDoctorPayload } from "~/utils/interfaces/Funnel";
import SelectedService from "~/utils/interfaces/LabServices";
import { User } from "~/utils/interfaces/User";
import { getUserServicesOrPacks } from "~/utils/offliner";
import handleTaskSwal from "~/utils/tasks";

const OfflineAppointmentCreate = (): JSX.Element => {
  const [error, setError] = useState<any>({});
  const [validationErrors, setValidationErrors] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [additionalCharge, setAdditionalCharge] = useState<string>("");
  const [isMassiveUpload, setIsMassiveUpload] = useState<boolean>(false);
  const [massiveUserData, setMassiveUserData] = useState<User[]>([]);
  const [sheetErrors, setSheetErrors] = useState<SheetError[]>([]);
  const [allDoctors, setAllDoctors] = useState<IDoctorPayload[]>([]);
  const [salesSource, setSalesSource] = useState<string>("marketplace");
  const appointmentDataRef = useRef<any>(null);
  const nurseAndLabServicesRef = useRef<any>(null);
  const userDataRef = useRef<any>(null);
  const discountCodeRef = useRef<any>(null);
  const doctorRef = useRef<Array<IDoctorData | null>>([null]);
  const isOperative = true;
  const { clear } = useAddessValue();

  const handleSubmit = async () => {
    // check validations
    let vErrors: string[] = [];
    vErrors = vErrors.concat(appointmentDataRef.current.validate());
    vErrors = vErrors.concat(nurseAndLabServicesRef.current.validate());
    vErrors = vErrors.concat(userDataRef.current.validate());

    if (
      doctorRef.current.some((doctor: IDoctorData | null) => {
        if (!doctor) return false;
        return !doctor.id && !doctor.name;
      })
    ) {
      vErrors.push("Cada doctor debe tener ID o nombre");
    }

    if (vErrors.length > 0) {
      setValidationErrors(vErrors);
      return;
    } else {
      setValidationErrors([]);
    }

    useScrollTop();

    const appointmentData = appointmentDataRef.current.getState();
    const nurseAndLabServices = nurseAndLabServicesRef.current.getState();
    const userDataState = userDataRef.current.getState();
    const userData = isMassiveUpload ? userDataState : JSON.parse(JSON.stringify(userDataState.userData));
    const doctorData = isMassiveUpload
      ? Array(userData.length).fill(doctorRef.current[0])
      : doctorRef.current.map((doctor: IDoctorData | null) => {
          if (!doctor) return null;
          if (!doctor.id) {
            return {
              ...doctor,
              id: doctor.name,
            };
          }
          return doctor;
        });
    const discountData = discountCodeRef.current.getState();
    const allServices = nurseAndLabServices.selectedItems.map((service: SelectedService) => ({
      id: service.id,
      amount: 1,
      fonasa: false,
    }));
    const userServices = isMassiveUpload ? [] : getUserServicesOrPacks(userDataState);
    const userPacks = isMassiveUpload ? [] : getUserServicesOrPacks(userDataState, true);
    // fix user data date of birth format
    for (let i = 0; i < userData.length; i++) {
      const formatted = moment(userData[i].date_of_birth, "YYYY-MM-DD").format("YYYY-MM-DDT12:00:00+00");
      userData[i].date_of_birth = formatted;
    }

    // check if any patient has no service attached
    // if not massive, check whether there is no user services, any is empty, or lengths are mismatched
    if (!isMassiveUpload) {
      const allUserServices: CartItemObjectType[][] = userDataState.userServices;
      if (
        !allUserServices?.length ||
        userData.length !== allUserServices.length ||
        allUserServices.some((user: CartItemObjectType[]) => !user.length)
      ) {
        vErrors = vErrors.concat(["Debe agendar exámenes para todos los pacientes"]);
      }
    } else {
      for (let i = 0; i < userData.length; i++) {
        userServices[i] = allServices;
      }
    }

    if (vErrors.length > 0) {
      setValidationErrors(vErrors);
      useScrollTop();
      return;
    } else {
      setValidationErrors([]);
    }

    setLoading(true);
    try {
      const timeblocks = appointmentData.selectedTimeblocks as string[];
      const initTimeblock = timeblocks[0].split("-")[0];
      const finishTimeblock = timeblocks[timeblocks.length - 1].split("-")[1];
      const formatedDate = appointmentData.date.format("YYYY-MM-DD");
      const newAppointment = {
        user_data: userData,
        user_services: userServices,
        user_packs: userPacks,
        doctors: doctorData,
        appointment_data: {
          is_operative: appointmentData.isOperative,
          managing_email: appointmentData.managingEmail,
          target_address: `${appointmentData.address}, ${appointmentData.comuna}, ${appointmentData.region}`,
          target_address_additional_info: appointmentData.additionalInfo,
          target_lat: appointmentData.coordinates.lat,
          target_lng: appointmentData.coordinates.lng,
          status: appointmentData.isFinished ? "finished" : "confirmed",
          contact_channel: appointmentData.selectedContactChannel
            ? (appointmentData.selectedContactChannel as string).toLowerCase()
            : undefined,
          target_residence_type: appointmentData.isApartment ? "apartment" : "house",
          begin_date: `${formatedDate} ${initTimeblock}`,
          end_date: `${formatedDate} ${finishTimeblock}`,
          comuna: appointmentData.comuna,
          region: appointmentData.region,
          country: appointmentData.country,
          address_line_1: appointmentData.address,
          is_factura: appointmentData.isFactura,
          finished: appointmentData.isFinished,
          operative_data: appointmentData.operativeData,
          referral_code: discountData.discountCodeValidated ? discountData.discountCode : null,
          blocks_needed: timeblocks.length,
          sales_source: salesSource,
          is_polygon_tester: true,
        },
        nurses: nurseAndLabServices.selectedNurses,
        additional_charge: +additionalCharge,
        is_massive_upload: isMassiveUpload,
      };

      if (appointmentData.isApartment) {
        // @ts-expect-error
        newAppointment.appointment_data.target_apartment_number = appointmentData.aptNumber;
      }
      const req = await appointmentService.createOffliner(newAppointment);
      if (req.status === 202) {
        Swal.fire({
          title: "En espera",
          text: "La subida está en espera del servidor. Por favor espere.",
          icon: "info",
          showConfirmButton: false,
        }).then((result) => {
          if (result.isDenied) {
            navigate("/massive_upload");
          }
        });
        handleTaskSwal(req.data.id);
      } else {
        throw Error("Error subiendo la cita");
      }
    } catch (err) {
      setError(err);

      await Swal.fire({
        title: "Whoops, something went wrong!",
        text: err.message,
        icon: "error",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    clear();
  }, []);

  useEffect(() => {
    clear();
  }, []);

  return (
    <PrivateRoute>
      <div className="px-24 py-16 rounded-lg bg-white border border-gray-200 flex flex-col">
        <LoadingError
          loading={loading}
          error={error}
        />
        <div className="flex justify-center my-4">
          <div className="flex flex-col justify-center">
            <h1>
              Crear <i>Operativo CL</i>
            </h1>
            <br />
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Grid
                item
                xs={4}
                style={{ textAlign: "right" }}
              >
                <Typography>Carga simple</Typography>
              </Grid>
              <Grid
                item
                xs={4}
                style={{ textAlign: "center" }}
              >
                <Switch
                  color="primary"
                  value={isMassiveUpload}
                  onChange={(e) => setIsMassiveUpload(e.target.checked as boolean)}
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <Typography>Carga masiva</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
            >
              <Grid
                item
                xs={4}
                style={{ textAlign: "right" }}
              />
              <Grid
                item
                xs={4}
                style={{ textAlign: "center" }}
              >
                <Switch
                  color="primary"
                  checked={isOperative}
                />
              </Grid>
              <Grid
                item
                xs={4}
              >
                <Typography>Operativo</Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        {validationErrors.length > 0 && (
          <div className="text-red-500 flex flex-col my-4 items-center">
            <ul>
              {validationErrors.map((error) => (
                <li key={error}>{error}</li>
              ))}
            </ul>
          </div>
        )}
        <AppointmentData
          ref={appointmentDataRef}
          setError={(err) => {
            setError(err);
          }}
          isOperative={isOperative}
        />
        <NurseAndLabServices
          ref={nurseAndLabServicesRef}
          setError={(err) => {
            setError(err);
          }}
          isMassiveUpload={isMassiveUpload}
          allowMultipleNurses={isOperative}
          locale="cl"
          doctorRef={doctorRef}
          allDoctors={allDoctors}
          salesSource={salesSource}
        />
        {isMassiveUpload && (
          <MassiveUserData
            ref={userDataRef}
            setLoading={setLoading}
            setError={(err) => setError(err)}
            setSheetErrors={setSheetErrors}
            sheetErrors={sheetErrors}
            setMassiveUserData={setMassiveUserData}
            massiveUserData={massiveUserData}
          />
        )}
        {!isMassiveUpload && (
          <UserData
            ref={userDataRef}
            setError={(err) => {
              setError(err);
            }}
            locale="cl"
            doctorRef={doctorRef}
            allDoctors={allDoctors}
            salesSource={salesSource}
          />
        )}
        <div className="flex">
          <DiscountCodeApplier
            isMassiveUpload={isMassiveUpload}
            nurseAndLabServicesRef={nurseAndLabServicesRef}
            usersRef={userDataRef}
            ref={discountCodeRef}
          />
          <SalesSourceSelector
            setValue={setSalesSource}
            currentValue={salesSource}
          />
        </div>
        <div className="flex justify-end items-center my-4">
          <button
            className="text-[#039BE5] bg-white border-[1.5px] border-[#039BE5] px-6 py-2 uppercase hover:bg-[#039BE5] hover:opacity-80 transition-all duration-300 rounded"
            onClick={handleSubmit}
            disabled={loading}
          >
            Crear cita
          </button>
        </div>
      </div>
    </PrivateRoute>
  );
};

export default OfflineAppointmentCreate;
